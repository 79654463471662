<template>
  <div>
    <div>
      <el-container>
        <el-aside
          class="home-aside"
          width="200px"
          v-if="user_type == 'admin'"
          style="
            height: 92vh;
            overflow-y: auto;
            padding-left: 15px;
            box-sizing: border-box;
          "
        >
          <el-row class="tac">
            <el-col>
              <el-menu :router="true" default-active="1">
                <el-menu-item index="1" :route="{ path: '/home/organizers' }">
                  <span slot="title">会议主办方</span>
                </el-menu-item>
                <el-menu-item index="2" :route="{ path: '/home/guests' }">
                  <span slot="title">嘉宾列表</span>
                </el-menu-item>
                <el-menu-item index="3" :route="{ path: '/home/brandparty' }">
                  <span slot="title">品牌方列表</span>
                </el-menu-item>
                <el-menu-item index="4" :route="{ path: '/home/orders' }">
                  <span slot="title">订单列表</span>
                </el-menu-item>
                <el-menu-item index="5" :route="{ path: '/home/agreement' }">
                  <span slot="title">协议列表</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </el-aside>
        <el-main
          class="home-content"
          :style="{
            height: user_type == 'admin' ? '92vh' : 'calc(100vh - 64px)',
          }"
        >
          <keep-alive include="record">
            <router-view></router-view>
          </keep-alive>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import "../assets/common/common.css";
import { getUserType } from "../utils/store";
export default {
  name: "Home",
  data() {
    return {
      user_type: "",
    };
  },
  mounted() {
    this.user_type = getUserType();
  },
  methods: {},
};
</script>

<style scoped lang="less">
.home-content {
  width: 100%;
  padding: 0px;
  height: 92vh;
  background-color: #F2F2F2;
  overflow-y: auto;
}

.home-aside {
  height: 92vh;
  background: #ffffff;
  box-shadow: 2px 0px 6px 1px rgba(0, 21, 41, 0.12);
  border-radius: 0px 0px 0px 0px;
}

.menu-item {
  margin-top: 15px;
  cursor: pointer;
  padding-left: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  color: #ffc569;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;

  &:hover {
    background-color: #eff1ff;
  }
}

.menu-item-selected {
  color: #ffc569;
  background-color: #eff1ff;
}
</style>
